import React from 'react';
import './TeamSection.css'; // Ensure this CSS file exists
import founderImage from '../assets/Chaitanya.jpg'; // Founder image
import ceoImage from '../assets/harsh.jpg'; // Co-founder CEO image
import cfoImage from '../assets/yashit.png'; // Co-founder CFO image

const socialMediaLinks = [
  {
    name: "Chaitanya Mehta",
    icon: "https://img.icons8.com/ios-filled/50/linkedin.png",
    alt: "LinkedIn",
    url: "https://www.linkedin.com/in/chaitanya-mehta-0019b02b2?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  {
    name: "Harsh Mehta",
    icon: "https://img.icons8.com/ios-filled/50/linkedin.png",
    alt: "LinkedIn",
    url: "https://www.linkedin.com/in/harsh--mehta?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  {
    name: "Yashit Mehta",
    icon: "https://img.icons8.com/ios-filled/50/linkedin.png",
    alt: "LinkedIn",
    url: "https://www.linkedin.com/in/yashit-mehta-01965a244/?originalSubdomain=in", // Placeholder URL; update if needed
  },
];

const renderSocialIcons = (memberName) =>
  socialMediaLinks
    .filter((link) => link.name === memberName)
    .map((link, index) => (
      <a
        key={index}
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
      >
        <img src={link.icon} alt={link.alt} />
      </a>
    ));

const TeamSection = () => {
  return (
    <div id="team" className="team-section">
      <div className="title-section">
        <h2 className="team-title">Aviators Of Aerovation</h2>
      </div>
      <div className="team-cards">

        {/* Founder & CTO Card */}
        <div className="team-card founder-card">
          <img src={founderImage} alt="Chaitanya Mehta" className="team-photo" />
          <div className="team-info">
            <a href="/chaitanya-profile" className="name">
              CHAITANYA MEHTA
            </a>
            <h2 className="role-title">Founder & CTO</h2>
            <div className="social-icons">{renderSocialIcons("Chaitanya Mehta")}</div>
          </div>
        </div>

        {/* Co-Founder & CEO Card */}
        <div className="team-card ceo-card">
          <img src={ceoImage} alt="Harsh Mehta" className="team-photo" />
          <div className="team-info">
            <a href="/harsh-profile" className="name">
              HARSH MEHTA
            </a>
            <h2 className="role-title">Co-Founder & CEO</h2>
            <div className="social-icons">{renderSocialIcons("Harsh Mehta")}</div>
          </div>
        </div>

        {/* Co-Founder & CFO Card */}
        
      </div>
    </div>
  );
};

export default TeamSection;

import React from 'react';
import './Footer.css';
import qrImage from '../assets/companyqr.png'; // Adjust the path as needed

// Social Media Icons
const socialMediaIcons = [
  
];

const Footer = () => {
  return (
    <footer className="footer-container">
      {/* Social Media Card */}
      <div className="social-media-card">
        <div className="qr-code">
          <img src={qrImage} alt="Company QR Code" />
        </div>
        <h3>LET'S PROPEL</h3>
        <div className="social-icons">
          {socialMediaIcons.map(icon => (
            <a key={icon.alt} href={icon.url} target="_blank" rel="noopener noreferrer">
              <img src={icon.src} alt={icon.alt} />
            </a>
          ))}
        </div>
      </div>

      {/* Footer Content */}
      <div className="footer-content">
        {/* About Section */}
        <div className="footer-column">
          <h4>About</h4>
          <a href="#"><p>Sankalpa V1</p></a>
          <a href="#"><p>Our Team</p></a>
          <a href="#"><p>Investors</p></a>
        </div>

        {/* Contact Section */}
        <div className="footer-column">
          <h4>Contact</h4>
          <p>Email: <a href="mailto:radkring@gmail.com">radkring@gmail.com</a></p>
          
        </div>

        {/* Address Section */}
        <div className="footer-column">
          <h4>Our Address</h4>
          <p>Block 5, TCET, Thakur village</p>
          <p>Kandivali East, Mumbai, India</p>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="copyright-card">
        <div className="copyright">
          <p>Copyright © {new Date().getFullYear()} RAD KRING. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

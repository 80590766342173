import React from 'react';
import Navbar from './components/Navbar';
import MainSection from './components/MainSection';
import Specifications from './components/Specifications';
import MapAnimation from './components/MapAnimation';
import DesignViews from './components/DesignViews';
import Services from './components/Services';
import Footer from './components/Footer';
import './App.css';

import TeamSection from './components/TeamSection';

// Inside the App component, add the TeamSection
function App() {
  return (
    <>
      <Navbar />
      <MainSection />
      <Specifications />
      <MapAnimation />
      <DesignViews />
      <Services />
      <TeamSection /> {/* Add this line */}
      <Footer />
    </>
  );
}

export default App;

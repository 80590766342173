import React, { useRef, useEffect } from 'react';
import './DesignViews.css'; // Import the styles

// Import images
import image1 from '../assets/sankalpa_v1_scaled_to_8_2024-Nov-10_02-47-30PM-000_CustomizedView12103764290_png.png';
import image2 from '../assets/interior.png';
import image3 from '../assets/sankalpa_v1_scaled_to_8_2024-Nov-10_02-40-09PM-000_CustomizedView44902752947_png.png';
import image4 from '../assets/sankalpa_v1_scaled_to_8_2024-Nov-10_02-38-28PM-000_CustomizedView11279354934_png.png';
import image5 from '../assets/sankalpa_v1_scaled_to_8_2024-Nov-10_02-41-02PM-000_CustomizedView32307381020_png.png';

const DesignViews = () => {
  const scrollContainerRef = useRef(null);
  const images = [image1, image2, image3, image4, image5];

  // Handle resizing
  useEffect(() => {
    const handleResize = () => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        const containerWidth = scrollContainer.clientWidth;
        const containerHeight = scrollContainer.clientHeight;

        // Adjust the container dimensions on resize (optional, based on your layout needs)
        scrollContainer.style.width = `${containerWidth}px`;
        scrollContainer.style.height = `${containerHeight}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call on mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="design-views-container">
      <div className="design-views-scroll" ref={scrollContainerRef}>
        {images.map((image, index) => (
          <div className="design-card" key={index}>
            <img src={image} alt={`Design view ${index + 1}`} className="design-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DesignViews;
